import { useWatch } from '@graphcommerce/ecommerce-ui'
import type { ProductAttributeFilterInput } from '@graphcommerce/graphql-mesh'
import { useProductFiltersPro, isFilterTypeEqual } from '@graphcommerce/magento-product'
import { FilterProps } from '@graphcommerce/magento-product/components/ProductFiltersPro/ProductFiltersProAggregations'
import {
  ActionCardListForm,
  ActionCard,
  filterNonNullableKeys,
  IconSvg,
  iconCirle,
  SectionHeader,
  Button,
  ActionCardProps,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { LazyHydrate, LazyHydrateProps } from '../LazyHydrate'
import { SearchBar } from './SearchBar'

type LazyActionCardProps = ActionCardProps & Pick<LazyHydrateProps, 'hydrated'>

function LazyActionCard(props: LazyActionCardProps) {
  const { hydrated } = props
  return (
    <LazyHydrate height='60px' hydrated={hydrated}>
      <ActionCard {...props} />
    </LazyHydrate>
  )
}

export function ProductFilterEqualSection(props: FilterProps) {
  const { aggregation } = props
  const { attribute_code, label, options } = aggregation
  const { form, submit, params } = useProductFiltersPro()
  const [search, setSearch] = useState<string>('')

  const { control } = form
  const attrCode = attribute_code as keyof ProductAttributeFilterInput

  // We are casting the name, because filters can have other types than equal which dont have the in property
  const name = `filters.${attrCode}.in` as 'filters.category_id.in'
  const currentFilter = useWatch({ control, name })
  const param = params.filters?.[attrCode]

  if (param && !isFilterTypeEqual(param)) throw new Error('Invalid filter type')

  const items = useMemo(
    () =>
      filterNonNullableKeys(options, ['label', 'count'])
        .filter((option) =>
          option.label
            .toLowerCase()
            .split(' ')
            .some((word) => word.startsWith(search.toLowerCase())),
        )
        .filter((item) => item.count !== 0)
        .map((option, i) => ({
          ...option,
          title: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ marginRight: 1 }}>{option.label}</Typography>
              {option.count !== null && (
                <Typography variant='caption' color='text.disabled'>
                  ({option.count})
                </Typography>
              )}
            </Box>
          ),
          image: attrCode?.toLowerCase().includes('color') && (
            <IconSvg
              src={iconCirle}
              sx={{ color: `${option?.label}`, fill: 'currentcolor' }}
              size='large'
            />
          ),
          hydrated: i < 5 ? true : undefined,
        })),
    [attrCode, options, search],
  )

  if (items.length < 1 && !search) return null

  return (
    <Box sx={{ my: 2 }}>
      <SectionHeader
        labelLeft={label}
        sx={{ mt: 0 }}
        labelRight={
          currentFilter && currentFilter.length > 0 ? (
            <Button
              variant='inline'
              color='primary'
              onClick={() => {
                form.resetField(name, { defaultValue: null })
              }}
            >
              <Trans id='Clear' />
            </Button>
          ) : undefined
        }
      />
      <SearchBar
        onSearch={setSearch}
        placeholder={i18n._(/* i18n */ 'Search...')}
        search={search}
      />
      {items.length < 1 || items.every((item) => item.count === 0) ? (
        <Typography variant='body1' color='text.secondary' sx={{ my: 1, textAlign: 'center' }}>
          <Trans id='No results found' />
        </Typography>
      ) : (
        <ActionCardListForm
          render={LazyActionCard}
          name={name}
          control={control}
          multiple
          layout='list'
          variant='default'
          size='medium'
          items={items}
        />
      )}
    </Box>
  )
}
