import { CategoryDescriptionFragment } from '@graphcommerce/magento-category/components/CategoryDescription/CategoryDescription.gql'
import { Button, extendableComponent } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, SxProps, Theme } from '@mui/material'
import { storeNoticeId } from '../../StoreNotice/StoreNotice'
import { descriptionId } from '../CategoryDescription/CategoryDescription'

export type CategoryIntroTextProps = Omit<CategoryDescriptionFragment, 'uid'> & {
  hasDescription?: boolean
  sx?: SxProps<Theme>
}

const cmpName = 'CategoryIntroText' as const
const parts = ['root'] as const
const { classes } = extendableComponent(cmpName, parts)

export function CategoryIntroText(props: CategoryIntroTextProps) {
  const hasWindow = typeof window !== 'undefined'

  const { frmwrk_category_intro_text, hasDescription, sx, ...divProps } = props

  const handleScroll = () => {
    const descriptionElement = hasWindow && document?.getElementById(descriptionId)
    const headerElement = hasWindow && document?.getElementById(storeNoticeId)
    const offset =
      descriptionElement && headerElement
        ? descriptionElement.offsetTop - headerElement.offsetTop
        : 0
    window.scrollTo({ top: offset, behavior: 'smooth' })
  }

  return (
    <>
      <Box
        {...divProps}
        className={classes.root}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: frmwrk_category_intro_text ?? '' }}
        maxWidth='md'
        sx={[
          (theme) => ({
            overflow: 'hidden',
            gridArea: 'description',
            m: `0 auto`,
            p: `0 ${theme.page.horizontal}`,
            textAlign: 'center',
            typography: 'subtitle1',
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
      {hasDescription ? (
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            mb: theme.spacings.sm,
          })}
        >
          <Button onClick={handleScroll}>
            <Trans id='Read more' />
          </Button>
        </Box>
      ) : undefined}
    </>
  )
}
