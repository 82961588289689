import TextField from '@mui/material/TextField'

type SearchBarProps = {
  placeholder: string
  search: string
  onSearch: (input: string) => void
}

export function SearchBar(props: SearchBarProps) {
  const { onSearch, placeholder, search } = props
  return (
    <TextField
      defaultValue={search}
      placeholder={placeholder}
      onChange={(e) => onSearch(e.target.value)}
      fullWidth
      sx={{ '& input': { padding: '5px 10px' }, marginY: 1 }}
    />
  )
}
