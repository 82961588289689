import { CategoryDescriptionFragment } from '@graphcommerce/magento-category/components/CategoryDescription/CategoryDescription.gql'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme } from '@mui/material'

export type CategoryDescriptionProps = Omit<CategoryDescriptionFragment, 'uid'> & {
  sx?: SxProps<Theme>
}

export const descriptionId = 'description'
const cmpName = 'CategoryDescription' as const
const parts = ['root'] as const
const { classes } = extendableComponent(cmpName, parts)

export function CategoryDescription(props: CategoryDescriptionProps) {
  const { name, description, display_mode, sx = [], ...divProps } = props

  return (
    <Box
      {...divProps}
      className={classes.root}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: description ?? '' }}
      id={descriptionId}
      maxWidth='lg'
      sx={[
        (theme) => ({
          width: '100%',
          m: `0 auto ${theme.spacings.sm}`,
          p: `0 ${theme.page.horizontal}`,
          textAlign: 'start',
          typography: 'subtitle1',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
