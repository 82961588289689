import { useWatch } from '@graphcommerce/ecommerce-ui'
import type { ProductAttributeFilterInput } from '@graphcommerce/graphql-mesh'
import {
  useProductFiltersPro,
  isFilterTypeEqual,
  ProductFilterParams,
} from '@graphcommerce/magento-product'
import { FilterProps } from '@graphcommerce/magento-product/components/ProductFiltersPro/ProductFiltersProAggregations'
import {
  ChipOverlayOrPopper,
  ActionCardListForm,
  ActionCard,
  filterNonNullableKeys,
  IconSvg,
  iconCirle,
  ActionCardProps,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { LazyHydrate, LazyHydrateProps } from '../LazyHydrate'
import { SearchBar } from './SearchBar'

type LazyActionCardProps = ActionCardProps & Pick<LazyHydrateProps, 'hydrated'>

function LazyActionCard(props: LazyActionCardProps) {
  const { hydrated } = props
  return (
    <LazyHydrate height='60px' hydrated={hydrated}>
      <ActionCard {...props} />
    </LazyHydrate>
  )
}

export function ProductFilterEqualChip(props: FilterProps) {
  const { aggregation } = props
  const { attribute_code, label, options } = aggregation
  const [search, setSearch] = useState<string>('')

  const { form, submit, params } = useProductFiltersPro()
  const { control } = form
  const attrCode = attribute_code as keyof ProductAttributeFilterInput

  // We are casting the name, because filters can have other types than equal which dont have the in property
  const name = `filters.${attrCode}.in` as 'filters.category_id.in'
  const canReset = useWatch({ control, name })
  const param = params.filters?.[attrCode]

  if (param && !isFilterTypeEqual(param)) throw new Error('Invalid filter type')

  const active = Boolean(param?.in?.length)
  const selectedLabel = filterNonNullableKeys(options)
    .filter((option) => param?.in?.includes(option.value))
    .map((option) => option && option.label)

  const items = useMemo(
    () =>
      filterNonNullableKeys(options, ['label', 'count'])
        .filter((option) =>
          option.label
            .toLowerCase()
            .split(' ')
            .some((word) => word.startsWith(search.toLowerCase())),
        )
        .filter((item) => item.count !== 0)
        .map((option, index) => ({
          ...option,
          title: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div>{option.label}</div>
              {option.count !== null && (
                <Box sx={{ typography: 'caption', color: 'text.disabled' }}>({option.count})</Box>
              )}
            </Box>
          ),
          image: attrCode?.toLowerCase().includes('color') && (
            <IconSvg
              src={iconCirle}
              sx={{ color: `${option?.label}`, fill: 'currentcolor' }}
              size='large'
            />
          ),
          hydrated: index < 5 ? true : undefined,
        })),
    [attrCode, options, search],
  )

  return (
    (items.length !== 0 || search) && (
      <ChipOverlayOrPopper
        label={label}
        chipProps={{ variant: 'outlined' }}
        overlayProps={{ sizeSm: 'minimal', sizeMd: 'minimal' }}
        onApply={submit}
        onReset={
          canReset
            ? () => {
                form.resetField(name, { defaultValue: null })
                return submit()
              }
            : undefined
        }
        onClose={submit}
        selectedLabel={selectedLabel}
        selected={active}
      >
        {() => (
          <>
            <SearchBar
              onSearch={setSearch}
              placeholder={i18n._(/* i18n */ 'Search...')}
              search={search}
            />
            {items.every((item) => item.count === 0) || items.length < 1 ? (
              <Typography
                variant='body1'
                color='text.secondary'
                sx={{ my: 1, textAlign: 'center' }}
              >
                <Trans id='No results found' />
              </Typography>
            ) : (
              <ActionCardListForm<LazyActionCardProps, ProductFilterParams>
                render={LazyActionCard}
                name={name}
                control={control}
                multiple
                layout='list'
                variant='default'
                size='medium'
                items={items}
              />
            )}
          </>
        )}
      </ChipOverlayOrPopper>
    )
  )
}
