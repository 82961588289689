import { iconClose, iconMenu, IconSvg } from '@graphcommerce/next-ui'
import { Typography, useMediaQuery } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React from 'react'

export function AccordionMenu(props) {
  const { title, children } = props
  const isDesktop = useMediaQuery('(min-width:960px)', { noSsr: true })

  const [expand, setExpand] = React.useState(false)
  const handleChange = (event: React.SyntheticEvent, newExpand: boolean) => {
    setExpand(newExpand)
  }
  const expanded = expand === true
  const handleClick = () => {
    if (expanded) setExpand(false)
  }

  return (
    <Accordion
      expanded={expanded || isDesktop}
      onChange={handleChange}
      onClick={handleClick}
      sx={(theme) => ({
        p: `${theme.spacings.xxs} ${theme.spacings.xs}`,
        borderRadius: '8px !important',
        boxShadow: 'none',
        '& .MuiAccordionSummary-content, MuiAccordionSummary-content.Mui-expanded, .MuiButtonBase-root, .MuiAccordionDetails-root':
          {
            minHeight: 'unset !important',
            m: '0 !important',
            p: 0,
          },
      })}
    >
      <AccordionSummary
        expandIcon={
          !isDesktop && (
            <IconSvg
              src={!expanded ? iconMenu : iconClose}
              size='large'
              sx={(theme) => ({ color: theme.palette.text.primary })}
            />
          )
        }
      >
        <Typography variant='h4'>{!isDesktop ? 'Menu' : title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
