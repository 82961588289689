import {
  LayoutHeaderClose,
  useShowClose,
} from '@graphcommerce/next-ui/Layout/components/LayoutHeaderClose'
import {
  LayoutHeaderContent,
  LayoutHeaderContentProps,
} from '@graphcommerce/next-ui/Layout/components/LayoutHeaderContent'
import { FloatingProps } from '@graphcommerce/next-ui/Layout/components/LayoutHeadertypes'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'

export type LayoutHeaderProps = FloatingProps &
  Omit<LayoutHeaderContentProps, 'left' | 'right'> & {
    /**
     * Button to display on the left side of the title
     *
     * - Assumes it can float on desktop
     * - Assumes it can not float on mobile
     */
    primary?: React.ReactNode
    /**
     * Button to display on the right side of the title
     *
     * - Assumes it can float on desktop
     * - Assumes it can not float on mobile
     */
    secondary?: React.ReactNode

    noAlign?: boolean

    sx?: SxProps<Theme>
  }

type ComponentStyleProps = {
  noAlign: boolean
  divider: boolean
  children: boolean
  floatingSm: boolean
  floatingMd: boolean
}

const { selectors, withState } = extendableComponent<ComponentStyleProps, 'LayoutHeader'>(
  'LayoutHeader',
  ['root'] as const,
)

export function LayoutHeader(props: LayoutHeaderProps) {
  const { children, divider, primary, secondary, noAlign = false, switchPoint, sx = [] } = props

  const showClose = useShowClose()

  const floatFallback = !children
  let { floatingSm = false, floatingMd = floatFallback } = props

  if (divider) floatingMd = false

  // When the primary or secondary is set, the header can't float on mobile even if the prop is passed.
  if (divider || primary || secondary) floatingSm = false

  const close = showClose && <LayoutHeaderClose />

  let left = secondary
  let right = primary

  if (!right) right = close
  else if (!left) left = close

  if (!left && !right && !children) return null

  const classes = withState({
    floatingSm,
    floatingMd,
    noAlign,
    children: !!children,
    divider: !!divider,
  })

  return (
    <Box
      className={classes.root}
      sx={[
        (theme) => ({
          zIndex: children ? theme.zIndex.appBar : theme.zIndex.appBar - 2,
          position: 'sticky',
          pointerEvents: 'none',
          top: '-1px',

          [theme.breakpoints.down('md')]: {
            height: theme.appShell.headerHeightSm,
            mt: `calc(${theme.appShell.headerHeightSm} * -1)`,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <LayoutHeaderContent
        left={left}
        right={right}
        divider={divider}
        floatingMd={floatingMd}
        floatingSm={floatingSm}
        switchPoint={switchPoint}
      >
        {children}
      </LayoutHeaderContent>
    </Box>
  )
}
LayoutHeader.selectors = selectors
