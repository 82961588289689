import { PageOptions } from '@graphcommerce/framer-next-pages'
import { Asset, hygraphPageContent, HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { flushMeasurePerf } from '@graphcommerce/graphql'
import {
  CategoryBreadcrumb,
  CategoryChildren,
  CategoryHeroNav,
  CategoryHeroNavTitle,
  CategoryMeta,
  getCategoryStaticPaths,
} from '@graphcommerce/magento-category'
import {
  extractUrlQuery,
  FilterTypes,
  getFilterTypes,
  parseParams,
  ProductFiltersDocument,
  ProductFiltersPro,
  ProductFiltersProAllFiltersChip,
  ProductFiltersProFilterChips,
  ProductFiltersProLimitChip,
  ProductFiltersProSortChip,
  ProductFiltersQuery,
  ProductListCount,
  ProductListDocument,
  ProductListFilters,
  ProductListFiltersContainer,
  ProductListPagination,
  ProductListParams,
  ProductListParamsProvider,
  ProductListQuery,
  ProductListSort,
} from '@graphcommerce/magento-product'
import { StoreConfigDocument, redirectOrNotFound } from '@graphcommerce/magento-store'
import {
  LayoutTitle,
  GetStaticProps,
  MetaRobots,
  Row,
  StickyBelowHeader,
} from '@graphcommerce/next-ui'
import { Box, Button, Container } from '@mui/material'
import { GetStaticPaths } from 'next'
import {
  ProductListItems,
  RowProduct,
  RowRenderer,
  LayoutNavigation,
  LayoutNavigationProps,
} from '../components'
import { CategoryDescription } from '../components/Category/CategoryDescription/CategoryDescription'
import { CategoryIntroText } from '../components/Category/CategoryIntroText/CategoryIntroText'
import { ProductFilterEqualChip } from '../components/Category/ProductFilterEqualChip'
import { ProductFilterEqualSection } from '../components/Category/ProductFilterEqualSection'
import {
  CallADruggistDocument,
  CallADruggistQuery,
} from '../components/Dialogs/MoreInformationDialog/CallADruggist.gql'
import { RowRecentBlogposts } from '../components/GraphCMS/RowRecentBlogposts/RowRecentBlogposts'
import { SidebarMenu } from '../components/GraphCMS/SidebarMenu/SidebarMenu'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { LayoutHeader } from '../components/Layout/components/LayoutHeader'
import { BlogContentBlockDocument, BlogContentBlockQuery } from '../graphql/BlogContentBlock.gql'
import { CategoryPageDocument, CategoryPageQuery } from '../graphql/CategoryPage.gql'
import { graphqlSsrClient, graphqlSharedClient } from '../lib/graphql/graphqlSsrClient'

export type CategoryProps = CategoryPageQuery &
  HygraphPagesQuery &
  ProductListQuery &
  ProductFiltersQuery & {
    filterTypes?: FilterTypes
    params?: ProductListParams
  } & CallADruggistQuery &
  BlogContentBlockQuery
export type CategoryRoute = { url: string[] }
type GetPageStaticPaths = GetStaticPaths<CategoryRoute>
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, CategoryProps, CategoryRoute>

function CategoryPage(props: CategoryProps) {
  const {
    categories,
    products,
    filters,
    params,
    filterTypes,
    pages,
    callADruggist,
    infoForm,
    rowRecentBlogposts,
  } = props

  const category = categories?.items?.[0]
  const isLanding = category?.display_mode === 'PAGE'
  const product = products?.items?.[0]
  const page = pages?.[0]

  const isCategory = params && category && product && filterTypes
  const categoryId = category?.categoryId?.toString()

  const filteredFilters = {
    ...filters,
    aggregations: filters?.aggregations?.filter((agg) => agg?.attribute_code !== 'category_uid'),
  }

  return (
    <>
      <CategoryMeta
        params={params}
        title={page?.metaTitle}
        metaDescription={page?.metaDescription}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[]}
        canonical={page?.url ? `/${page.url}` : undefined}
        {...category}
      />
      <LayoutHeader floatingMd>
        <LayoutTitle size='small' component='span'>
          {category?.name ?? page.title}
        </LayoutTitle>
      </LayoutHeader>
      {page?.url === 'cookieverklaring' && (
        <Button
          id='cookiebotBtn'
          onClick={() => globalThis.Cookiebot.renew()}
          variant='pill'
          color='primary'
          sx={(theme) => ({
            position: 'fixed',
            m: theme.spacings.xxs,
            mb: 0,
            bottom: theme.spacings.xxs,
            zIndex: '99',
            [theme.breakpoints.down('sm')]: {
              position: 'sticky',
              bottom: 'unset',
              top: '55px',
              width: 'calc(100% - 30px)',
            },
          })}
        >
          Cookievoorkeuren aanpassen
        </Button>
      )}
      {isCategory && (
        <Box sx={{ my: 2 }}>
          <Container>
            <CategoryBreadcrumb
              {...category}
              separator='-'
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  fontSize: theme.typography.caption,
                  '& p': {
                    fontSize: theme.typography.caption,
                  },
                },
              })}
            />
          </Container>
        </Box>
      )}

      {!isLanding && isCategory && (
        <Container maxWidth='lg'>
          <LayoutTitle
            component='h1'
            variant='h2'
            sx={(theme) => ({
              my: `${theme.spacings.sm} !important`,

              '& .LayoutTitle-title': {
                textAlign: 'center',
                fontWeight: theme.typography.fontWeightBold,
              },
            })}
          >
            {category?.name ?? page.title}
          </LayoutTitle>
        </Container>
      )}

      {!isLanding && !isCategory && (
        <Container maxWidth='lg'>
          <LayoutTitle
            component='h1'
            variant='h2'
            sx={(theme) => ({
              '& .LayoutTitle-title': {
                textAlign: 'center',
                fontWeight: theme.typography.fontWeightBold,
              },
            })}
          >
            {category?.name ?? page.title}
          </LayoutTitle>
        </Container>
      )}
      {isCategory && isLanding && (
        <CategoryHeroNav
          {...category}
          asset={pages?.[0]?.asset && <Asset asset={pages[0].asset} loading='eager' />}
          title={<CategoryHeroNavTitle>{category?.name}</CategoryHeroNavTitle>}
        />
      )}

      {isCategory && !isLanding && (
        <Box>
          <CategoryIntroText
            frmwrk_category_intro_text={category.frmwrk_category_intro_text}
            hasDescription={!!category.description}
          />
          <Row maxWidth='lg' sx={{ mb: 0, pr: 0 }}>
            <CategoryChildren
              params={params}
              sx={(theme) => ({
                '& .CategoryChildren-scroller': {
                  m: 0,
                  gap: theme.spacings.xs,
                },
                '& .CategoryChildren-link': {
                  m: 0,
                  py: '3px',
                  px: theme.spacings.md,
                  borderRadius: '10px',
                  background: theme.palette.background.paper,
                  textAlign: 'center',
                  fontWeight: theme.typography.fontWeightBold,
                },
                '& .CategoryChildren-link:before': {
                  display: 'none',
                },
              })}
            >
              {category?.children}
            </CategoryChildren>
          </Row>
          <StickyBelowHeader>
            {import.meta.graphCommerce.productFiltersPro ? (
              <ProductFiltersPro params={params}>
                <ProductListFiltersContainer
                  sx={(theme) => ({
                    '& .ProductListFiltersContainer-scroller': {
                      gap: theme.spacings.xxs,
                    },
                    '& .Scroller-root .MuiChip-root': {
                      borderColor: { xs: theme.palette.text.secondary, md: 'unset' },
                      transition: '0.5s',
                    },
                    '& .isSticky .MuiChip-root': {
                      boxShadow: { xs: theme.shadows[3], md: 0 },
                      transition: '0.5s',
                    },
                  })}
                >
                  <ProductFiltersProFilterChips
                    aggregations={filteredFilters.aggregations}
                    appliedAggregations={products.aggregations}
                    filterTypes={filterTypes}
                    renderer={{ FilterEqualTypeInput: ProductFilterEqualChip }}
                  />
                  <ProductFiltersProSortChip {...products} />
                  <ProductFiltersProLimitChip />
                  <ProductFiltersProAllFiltersChip
                    {...products}
                    {...filteredFilters}
                    appliedAggregations={products.aggregations}
                    filterTypes={filterTypes}
                    renderer={{
                      FilterEqualTypeInput: ProductFilterEqualSection,
                    }}
                  />
                </ProductListFiltersContainer>
              </ProductFiltersPro>
            ) : (
              <ProductListParamsProvider value={params}>
                <ProductListFiltersContainer>
                  <ProductListSort
                    sort_fields={products?.sort_fields}
                    total_count={products?.total_count}
                  />
                  <ProductListFilters {...filters} filterTypes={filterTypes} />
                </ProductListFiltersContainer>
              </ProductListParamsProvider>
            )}
          </StickyBelowHeader>

          <Container maxWidth='lg'>
            <ProductListCount
              sx={(theme) => ({
                '& .ProductListCount-count': {
                  color: theme.palette.text.secondary,
                },
                '& .ProductListCount-line': {
                  borderColor: theme.palette.text.secondary,
                },
              })}
              total_count={products?.total_count}
            />
            <ProductListItems
              title={category.name ?? ''}
              categoryId={categoryId}
              items={products?.items}
              promotionItems={
                page?.modular?.components.find((c) => c.__typename === 'PromotionBlockList')?.blocks
              }
              callADruggist={callADruggist}
              infoForm={infoForm}
              loadingEager={1}
              sx={(theme) => ({
                gap: { xs: theme.spacings.md, xxl: theme.spacings.sm },
                gridTemplateColumns: {
                  xs: `repeat(2, 1fr)`,
                  md: `repeat(4, 1fr)`,
                  lg: `repeat(6, 1fr)`,
                },
              })}
              onClick={() => {}}
              titleComponent={undefined}
            />
            <ProductListPagination page_info={products?.page_info} params={params} />
          </Container>
        </Box>
      )}

      {category?.description && (
        <CategoryDescription
          description={category.description}
          sx={(theme) => ({ pb: theme.spacings.sm })}
        />
      )}
      {page && (
        <Container maxWidth='lg'>
          <Box
            sx={(theme) => ({
              display: {
                xs: 'block',
                md: 'flex',
                '& .cta-btn': {
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  borderRadius: '10px',
                  py: theme.spacings.xxs,
                  px: theme.spacings.sm,
                  textTransform: 'uppercase',
                  display: 'inline-table',
                  fontWeight: '600',
                },
                '& .cta-btn:hover': {
                  background: theme.palette.primary.contrastText,
                  color: theme.palette.primary.main,
                },
              },
            })}
          >
            <SidebarMenu pages={pages} />
            <Box sx={{ width: '100%', '& div': { p: '0' } }}>
              <RowRenderer
                content={page.content}
                renderer={{
                  RowProduct: (rowProps) => (
                    <RowProduct
                      {...rowProps}
                      {...products?.items?.[0]}
                      items={products?.items?.slice(0, 8)}
                    />
                  ),
                }}
              />
            </Box>
          </Box>
        </Container>
      )}
      {rowRecentBlogposts && <RowRecentBlogposts {...rowRecentBlogposts} />}
    </>
  )
}

const pageOptions: PageOptions<LayoutNavigationProps> = {
  Layout: LayoutNavigation,
}
CategoryPage.pageOptions = pageOptions

export default CategoryPage

export const getStaticPaths: GetPageStaticPaths = async ({ locales = [] }) => {
  // Disable getStaticPaths while in development mode
  if (import.meta.graphCommerce.limitSsg) return { paths: [], fallback: 'blocking' }

  const path = (loc: string) => getCategoryStaticPaths(graphqlSsrClient(loc), loc)
  const paths = (await Promise.all(locales.map(path))).flat(1)
  return { paths, fallback: 'blocking' }
}

export const getStaticProps: GetPageStaticProps = async ({ params, locale }) => {
  const [url, query] = extractUrlQuery(params)
  if (!url || !query) return { notFound: true }

  const client = graphqlSharedClient(locale)
  const conf = client.query({ query: StoreConfigDocument })
  const filterTypes = getFilterTypes(client)

  const staticClient = graphqlSsrClient(locale)

  const categoryPage = staticClient.query({ query: CategoryPageDocument, variables: { url } })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  const blogPost = staticClient.query({
    query: BlogContentBlockDocument,
  })

  const callADruggist = staticClient.query({ query: CallADruggistDocument })

  const productListParams = parseParams(url, query, await filterTypes)

  const filteredCategoryUid = productListParams && productListParams.filters.category_uid?.in?.[0]
  const category = categoryPage.then((res) => res.data.categories?.items?.[0])

  let categoryUid = filteredCategoryUid
  if (!categoryUid) {
    categoryUid = (await category)?.uid ?? ''
    if (productListParams) productListParams.filters.category_uid = { in: [categoryUid] }
  }

  const pages = hygraphPageContent(staticClient, url, category)

  const hasPage = filteredCategoryUid ? false : (await pages).data.pages.length > 0

  const hasCategory = Boolean(productListParams && categoryUid)

  if (!productListParams || !(hasPage || hasCategory))
    return redirectOrNotFound(staticClient, conf, params, locale)

  if (!hasCategory) {
    return {
      props: {
        ...(await categoryPage).data,
        ...(await pages).data,
        ...(await layout).data,
        // ...(await blogPost).data, //May need this when DA wants blogposts on plain categories
        apolloState: await conf.then(() => client.cache.extract()),
      },
      revalidate: 60 * 20,
    }
  }

  const filters = staticClient.query({
    query: ProductFiltersDocument,
    variables: { filters: { category_uid: { eq: categoryUid } } },
  })

  const products = staticClient.query({
    query: ProductListDocument,
    variables: {
      ...productListParams,
      filters: { ...productListParams.filters, category_uid: { eq: categoryUid } },
    },
  })

  if ((await products).errors) return { notFound: true }

  const { category_name, category_url_path } =
    (await categoryPage).data.categories?.items?.[0]?.breadcrumbs?.[0] ?? {}

  const up =
    category_url_path && category_name
      ? { href: `/${category_url_path}`, title: category_name }
      : { href: `/`, title: 'Home' }

  const result = {
    props: {
      ...(await categoryPage).data,
      ...(await products).data,
      ...(await pages).data,
      ...(await filters).data,
      ...(await layout).data,
      ...(await callADruggist).data,
      ...(await blogPost).data,
      filterTypes: await filterTypes,
      params: productListParams,
      apolloState: await conf.then(() => client.cache.extract()),
      up,
    },
    revalidate: 60 * 20,
  }
  flushMeasurePerf()
  return result
}
